import FunnelKey from "src/constants/funnelKey";
import { FUNNEL_QUESTIONNAIRE_ELIGIBILITY } from "src/constants/misc";
import { SignupCustomCampaignName } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import FactorySignupCustomCampaign from "src/ui/components/SignupCustomContent/campaigns/FactorySignupCustomCampaign";

const campaignInstances = () => [
  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.lillyTeam,
    prettyName: "Lilly Team",
    funnelKey: FunnelKey.lillyTeam,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/534da4ef5cfc1e325a159785ba16c741fcfc3d0a-631x344.svg",
    logoSize: "small",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: "nnr4MisyYRqS", // employer
          fieldType: "choice",
          fieldValue: {
            choiceId: "aawBnc5U8xA8",
            value: "Lilly"
          }
        },
        {
          questionId: "Xr1URwgDrLB8", // healthplan
          fieldType: "choice",
          fieldValue: {
            choiceId: "eEXojUUbdJef",
            value: "Lilly"
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.stateOfIndiana,
    prettyName: "State of Indiana",
    funnelKey: FunnelKey.stateOfIndiana,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/536581e6692a17af77dc2c15085a9e3b019c5668-3840x848.png",
    logoSize: "large",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: "nnr4MisyYRqS", // employer
          fieldType: "choice",
          fieldValue: {
            choiceId: "PxcU4opoCxXT",
            value: "State of Indiana"
          }
        },
        {
          questionId: "Xr1URwgDrLB8", // healthplan
          fieldType: "choice",
          fieldValue: {
            choiceId: "nvwPwhoPWjJv",
            value: "Anthem Indiana"
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.evernorth,
    prettyName: "Evernorth",
    funnelKey: FunnelKey.evernorth,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/eca72b7fe025eb6e20dc82459a575706d2666423-438x77.svg",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: "Xr1URwgDrLB8", // healthplan
          fieldType: "choice",
          fieldValue: {
            choiceId: "EhJryoxsCGQQ",
            value: "Evernorth"
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.eternalHealth,
    prettyName: "eternalHealth",
    funnelKey: FunnelKey.eternalHealth,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/7c113e63aeb02a83054c3171520766c612cefd75-399x73.svg",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: "Xr1URwgDrLB8", // healthplan
          fieldType: "choice",
          fieldValue: {
            choiceId: "GYJnhYUTPZGc",
            value: "eternalHealth"
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.kwikTrip,
    prettyName: "Kwik Trip",
    funnelKey: FunnelKey.kwikTrip,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/cc79972cfb5a8c427e39373deae3f50d4369cd66-375x60.png",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: "nnr4MisyYRqS", // employer
          fieldType: "choice",
          fieldValue: {
            choiceId: "oZlpyMeJMxwX",
            value: "Kwik Trip"
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.Prescryptive,
    prettyName: "Prescryptive",
    funnelKey: FunnelKey.prescryptive,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/ef478da257d2f1cbc3482d519d243617ab42e211-349x138.webp",
    filterAnswerOptions: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: "nnr4MisyYRqS", // employer
          includeOptions: [
            "xBdIqSpWXKNF", // prescryptive
            "1nJ65cyhh18G" // first choice
          ]
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.Universal,
    prettyName: "Universal",
    funnelKey: FunnelKey.universal,
    getSubscriptionFromSuggestedTreatmentPlan: true,
    personalisedFinalSuccessScreen: true
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.Lilly,
    prettyName: "Lilly",
    funnelKey: FunnelKey.lilly,
    getSubscriptionFromSuggestedTreatmentPlan: true,
    personalisedFinalSuccessScreen: true
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.SmithRx,
    prettyName: "SmithRx",
    funnelKey: FunnelKey.smithrx,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/ebe6b7808efc33fe80bb8a5259f867965cc656ca-180x34.svg",
    filterAnswerOptions: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: "nnr4MisyYRqS", // employer
          includeOptions: ["YWlwgEuAOeI7", "KdoCwOdxKMsp"] // only JJ Safety and Nimnicht
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.wipfli,
    prettyName: "Wipfli",
    funnelKey: FunnelKey.wipfli,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/6d4ff0f3fc17c198204eb23366484630328d0437-924x301.png",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: "nnr4MisyYRqS", // employer
          fieldType: "choice",
          fieldValue: {
            choiceId: "hroIvEv66bHy",
            value: "Wipfli"
          }
        }
      ]
    }
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.transcarent,
    prettyName: "Transcarent",
    funnelKey: FunnelKey.transcarent
  }),

  new FactorySignupCustomCampaign({
    name: SignupCustomCampaignName.amazon,
    prettyName: "Amazon",
    funnelKey: FunnelKey.amazon,
    logo: "https://cdn.sanity.io/images/g38rxyoc/production/6518e9c474f5e3b423f74771815f8ec280d39275-1024x346.png",
    answerOverrides: {
      [FUNNEL_QUESTIONNAIRE_ELIGIBILITY]: [
        {
          questionId: "nnr4MisyYRqS", // employer
          fieldType: "choice",
          fieldValue: {
            choiceId: "vAToTGnruTKV", // TODO this is not final
            value: "Amazon"
          }
        }
      ]
    }
  })
];

const SignupCustomCampaignGenericInstances = campaignInstances().map((c) =>
  c.get()
);

export default SignupCustomCampaignGenericInstances;
