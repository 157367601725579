import { Button } from "@9amhealth/shared";
import styled from "@emotion/styled";
import { Cubit } from "blac";
import type { FC } from "react";
import React, { useEffect } from "react";
import Language, { Locale } from "src/constants/language";
import envVariables from "src/lib/envVariables";
import { changeLanguage, getSupportedUserLocale } from "src/lib/i18next";
import reportErrorSentry from "src/lib/reportErrorSentry";
import AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import UserCubit from "src/state/UserCubit/UserCubit";
import UserPreferencesCubit, {
  UserPreferenceKeys
} from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { useBloc, userState } from "src/state/state";
import CollapseDebug from "./CollapseDebug";
import DebugCapInfo from "./DebugCapInfo";
import DebugFeatureFlags from "./DebugFeatureFlags";
import DebugTaskMocker from "./DebugTaskMocker";
import DebugVersion from "./DebugVersion";
import { BiometricVerificationBloc } from "src/hybrid/components/BiometricVerification";
import { Credentials } from "@capgo/capacitor-native-biometric";
import HealthSyncBloc from "src/state/HealthSyncBloc/HealthSyncBloc";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";
import DebugPartnerSession from "./DebugPartnerSession";

interface DebugBlocState {
  extra: Record<string, unknown>;
  show: boolean;
}

class DebugBloc extends Cubit<DebugBlocState> {
  isOpenSessStorageKey = "debug-bloc-is-open";

  constructor() {
    super({ extra: {}, show: false });
  }

  init = () => {
    const isOpen = sessionStorage.getItem(this.isOpenSessStorageKey);
    if (isOpen === "true") {
      this.setShow(true);
    }
  };

  triggerWord = "debug9am";
  lastKeys = "";

  typeListener = (e: KeyboardEvent) => {
    this.lastKeys = this.lastKeys + e.key;
    // always keep at same length
    this.lastKeys = this.lastKeys.slice(-this.triggerWord.length);
    if (this.lastKeys === this.triggerWord) {
      this.setShow(true);
    }
  };

  countMovePastMiddle = 0;
  middle = window.innerWidth / 2;
  aboveMiddle = false;
  touchListener = (e: TouchEvent) => {
    if (e.touches.length > 1) {
      return;
    }
    const [touch] = e.touches;
    if (touch.clientX > this.middle) {
      if (!this.aboveMiddle) {
        this.countMovePastMiddle++;
        if (this.countMovePastMiddle > 5) {
          this.setShow(true);
        }
      }
      this.aboveMiddle = true;
    } else {
      this.aboveMiddle = false;
    }
  };

  touchEndListener = () => {
    this.countMovePastMiddle = 0;
  };

  setShow = (show: boolean): void => {
    this.emit({ ...this.state, show });

    sessionStorage.setItem(this.isOpenSessStorageKey, String(show));
  };

  addCustomListeners = () => {
    document.addEventListener("keydown", this.typeListener, { passive: true });
    window.addEventListener("touchmove", this.touchListener, { passive: true });
    window.addEventListener("touchend", this.touchEndListener, {
      passive: true
    });
  };

  removeCustomListeners = () => {
    document.removeEventListener("keydown", this.typeListener);
    window.removeEventListener("touchmove", this.touchListener);
    window.removeEventListener("touchend", this.touchEndListener);
  };

  setExtra = (extra: Record<string, unknown>): void => {
    this.emit({ ...this.state, extra });
  };
}

export const DebugBlocController = new DebugBloc();

const Pos = styled.div`
  label: AppDebug;
  position: absolute;
  inset: 0;
  background-color: #ffffff;
  padding: calc(var(--ion-safe-area-top, 0px) + 1rem) 1rem
    calc(var(--ion-safe-area-bottom, 0px) + 1rem);
  border-top-left-radius: 0.5rem;
  font-size: 0.7rem;
  font-weight: 300;
  color: #000;
  max-width: 100vw;
  overflow: auto;
  white-space: nowrap;
  z-index: 999999999;
  box-sizing: border-box;

  input {
    padding: 0.2rem;
    border: 1px solid #000;
  }
`;

const EditPrefValue: FC<{
  value: string;
  propKey: string;
  onChange: (k: string, v: unknown) => void;
}> = ({ value, propKey, onChange }) => {
  const [editing, setEditing] = React.useState(false);
  const [newValue, setNewValue] = React.useState(value);

  const save = () => {
    if (newValue === "") {
      return;
    }

    try {
      const parsed = JSON.parse(newValue);
      onChange(propKey, parsed);
      setEditing(false);
    } catch (e) {
      alert("Invalid JSON");
    }
  };

  return (
    <div>
      <b>{propKey}: </b>
      {editing ? (
        <>
          <input
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
          <button onClick={save}>Save</button>
          <button onClick={() => setEditing(false)}>Cancel</button>
        </>
      ) : (
        <>
          <span>{value}</span>
          <button onClick={() => setEditing(true)}>Edit</button>
        </>
      )}
    </div>
  );
};

const DebugAuth: FC = () => {
  const [, auth] = useBloc(AuthenticationBloc);
  const [bioState, bio] = useBloc(BiometricVerificationBloc);
  const [server, setServer] = React.useState<string | null>();
  const [credentials, setCredentials] = React.useState<Credentials>();
  React.useEffect(() => {
    void bio.getServer().then((server) => setServer(server));
  }, []);

  return (
    <>
      <div>
        {" "}
        <button onClick={() => void auth.logout()}>Logout</button>{" "}
      </div>
      <br />
      <div>
        {" "}
        <button onClick={() => void auth.refreshAccessToken()}>
          Refresh Token
        </button>{" "}
      </div>
      <h3>Biometrics</h3>
      <pre>{JSON.stringify(bioState, null, 2)}</pre>
      <ul>
        <li>server: {server}</li>
        <li>credentials: {credentials?.username ?? "none"}</li>
      </ul>
      <div>
        {" "}
        <button onClick={() => void bio.getCredentials().then(setCredentials)}>
          getCredentials
        </button>{" "}
      </div>
    </>
  );
};

const DebugHealthSync: FC = () => {
  const [healthState, { runBgTask }] = useBloc(HealthSyncBloc);
  return (
    <div>
      <div>
        Health Sync:
        <ul>
          <li>available: {healthState.available ? "yes" : "no"}</li>
          <li>steps authorized status: {healthState.stepsAuthorized}</li>
          <li>steps data: {healthState.stepsData.length}</li>
          <li>Last sync: {healthState.lastImported?.toLocaleString()}</li>
        </ul>
        <Button
          small
          onPress={() =>
            AppQueryPopupsController.openPopup(AppPopup.healthSyncSetup)
          }
        >
          Open Setup
        </Button>
        <Button small onPress={() => void runBgTask()}>
          Run Background Task Handler
        </Button>
      </div>
    </div>
  );
};

/**
 * Show debug view, triggered by typing "debug9am" or swiping right and left 6 times on the screen.
 */
const AppDebug: FC = () => {
  const [
    { show, extra },
    { setShow, addCustomListeners, removeCustomListeners, init }
  ] = useBloc(DebugBloc, {
    create: () => DebugBlocController
  });
  const [userPref, { updateUserPreferences }] = useBloc(UserPreferencesCubit);
  const [{ userData }] = useBloc(UserCubit);

  const locale = getSupportedUserLocale();

  useEffect(() => {
    addCustomListeners();
    init();
    return removeCustomListeners;
  }, []);

  const handlePrefChange = (k: string, v: unknown) => {
    void updateUserPreferences({ [k]: v }).then(() => {
      alert(`Saved ${k}`);
    });
  };

  if (!show) return <></>;

  const envKeys = Object.keys(envVariables);

  const populate = () => {
    void userState.populateUserprofileWithDemoData();
  };

  const handleChange = (loc: Locale) => {
    void updateUserPreferences({
      [UserPreferenceKeys.language]: loc
    });
    void changeLanguage(loc);
  };

  return (
    <Pos aria-hidden="true">
      <Button small onPress={() => setShow(false)}>
        Close
      </Button>
      <CollapseDebug title="Env Vars">
        {envKeys.map((k) => {
          const val = String(
            (envVariables as unknown as Record<string, string>)[k]
          );
          return (
            <div key={k}>
              <b>{k}</b> <span>{val}</span>
            </div>
          );
        })}
        <div>
          <b>href</b> <span>{window.location.href}</span>
        </div>
        <div>
          <b>hostname</b> <span>{window.location.hostname}</span>
        </div>
      </CollapseDebug>
      <CollapseDebug title="User Details">
        <pre>{JSON.stringify(userData, null, 2)}</pre>
      </CollapseDebug>

      <CollapseDebug title="User Preferences">
        {Object.keys(userPref).map((k) => {
          const val = JSON.stringify(
            (userPref as unknown as Record<string, unknown>)[k]
          );
          return (
            <EditPrefValue
              key={k}
              value={val}
              propKey={k}
              onChange={handlePrefChange}
            />
          );
        })}
      </CollapseDebug>

      <CollapseDebug title="Feature Flags">
        <DebugFeatureFlags />
      </CollapseDebug>

      <CollapseDebug title="Task Mocker">
        <DebugTaskMocker />
      </CollapseDebug>

      <CollapseDebug title="Health Sync">
        <DebugHealthSync />
      </CollapseDebug>

      <CollapseDebug title="Auth">
        <DebugAuth />
      </CollapseDebug>

      <CollapseDebug title="Partner Session">
        <DebugPartnerSession />
      </CollapseDebug>

      <CollapseDebug title="Capacitor">
        <DebugCapInfo />
      </CollapseDebug>

      <CollapseDebug title="Version">
        <DebugVersion />
      </CollapseDebug>

      <CollapseDebug title="Extra">
        {Object.keys(extra).map((k) => {
          const val = String(extra[k]);
          return (
            <div key={k}>
              <b>{k}</b> <span>{val}</span>
            </div>
          );
        })}
      </CollapseDebug>

      <Button
        small
        onPress={() => {
          reportErrorSentry(new Error("Debug Error 2"));
        }}
      >
        Send Sentry Error
      </Button>

      <Button small onPress={() => populate()}>
        Demo User Populate
      </Button>

      <nine-spacer s="lg"></nine-spacer>
      <label>
        Language:
        <select
          name="language"
          value={locale}
          onChange={(e) => handleChange(e.target.value as Locale)}
        >
          {" "}
          <option value={Locale.en} key={Language.en}>
            English
          </option>
          <option value={Locale.es} key={Language.es}>
            Español
          </option>{" "}
        </select>
      </label>
    </Pos>
  );
};

export default AppDebug;
